import React, { useState } from "react"
import styled from "styled-components"
import Parser from "html-react-parser"

import { TitleH2 } from "../../elements/Title"
import Fleche from "../../../images/icons/fleche-blanche.svg"

const Content = styled.div`
  height: 680px;
  padding: 45px 20px 20px 20px;
  background: ${props => props.theme.secondary};
  .title1 {
    margin-bottom: 3rem;
  }
`

const Item = styled.div`
  margin-bottom: 2rem;
`

const ItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const SubTitle = styled.p`
  color: white;
  font-size: 20px;
  margin-bottom: 0;
`

const FlecheIcon = styled.img`
  transition: transform 0.2s ease-in-out;
  transform: ${props => (props.isActive ? "rotate(90deg)" : "rotate(-90deg)")};
`

const Text = styled.div`
  padding-left: 15px;
  margin-right: 20px;
  margin-top: 20px;
  border-left: 2px solid white;
  margin-left: 5px;
  display: ${props => (props.isActive ? "block" : "none")};
  opacity: ${props => (props.isActive ? "1" : "0")};
  transition: display 0.2s ease-in-out, opacity 0.4s ease-in-out;
  p {
    color: white;
  }
`

const Intro = styled.p`
  color: white;
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
`

const ValeursMobile = ({ title, items }) => {
  const [valeur, setValeur] = useState(0)

  const handleClick = value => {
    if (value === valeur) {
      setValeur()
    } else {
      setValeur(value)
    }
  }

  return (
    <React.Fragment>
      <Content>
        <TitleH2 className="title1" color="white" position="center">
          {title}
        </TitleH2>
        {items.map((item, index) => (
          <Item onClick={() => handleClick(index)} key={index}>
            <ItemHeader>
              <SubTitle isActive={valeur === index}>
                {item.titre_valeur}
              </SubTitle>
              <FlecheIcon src={Fleche} isActive={valeur === index} />
            </ItemHeader>
            <Text isActive={valeur === index}>
              <Intro>{item.intro_valeur}</Intro>
              {Parser(item.texte_valeur)}
            </Text>
          </Item>
        ))}
      </Content>
    </React.Fragment>
  )
}

export default ValeursMobile
