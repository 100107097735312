import React from "react"

import { DeviceContext } from "../../elements/DeviceProvider"
import Section from "../../elements/Section"
import ValeursDesktop from "./Desktop"
import ValeursMobile from "./Mobile"

const Valeurs = ({ title, items }) => {
  return (
    <Section border padding="0" id="values">
      <DeviceContext.Consumer>
        {deviceValues => {
          return deviceValues.width ? (
            deviceValues.windowWidth < 1024 ? (
              <ValeursMobile title={title} items={items} />
            ) : (
              <ValeursDesktop title={title} items={items} />
            )
          ) : null
        }}
      </DeviceContext.Consumer>
    </Section>
  )
}

export default Valeurs
