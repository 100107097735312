import React from "react"
import styled from "styled-components"

import globalVariables from "../../globalVariables"
import theme from "../../theme"

import bannerPurpleAgence from "../../../images/banner_home_agence.png"
import onRecrute from "../../../images/on_recrute.png"

const WrapperSlider = styled.div`
  position: relative;
`

const CardAgence = styled.div`
  height: 359px;
  left: -155px;
  position: absolute;
  top: -50px;
  width: 359px;
  z-index: 1;

  @media (max-width: ${globalVariables.maxMobile}) {
    display: none;
  }
`

const ImageOnRecrute = styled.img`
  width: 407px;
  height: 274px;
  margin-top: 40px;
  position: relative;
  z-index: 2;
  border-radius: 18px;
  box-shadow: 8px 10px 0px ${theme.primaryDarkPurple};
  @media (max-width: ${globalVariables.maxMobile}) {
    display: none;
  }
`

const RightCardRecrutement = () => (
  <WrapperSlider>
    <CardAgence>
      <img src={bannerPurpleAgence} />
    </CardAgence>
    <ImageOnRecrute src={onRecrute} />
  </WrapperSlider>
)

export default RightCardRecrutement
