import React, { useRef } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Flip from "react-reveal/Flip"

import Section from "../../elements/Section"
import Container from "../../elements/Container"
import Title from "../../elements/Title"
import globalVariables from "../../globalVariables"
import { NextArrow, PrevArrow } from "../../elements/ArrowCarousel"

const WrapperSlider = styled.div`
  @media (max-width: ${globalVariables.maxMobile}) {
    width: 250px;
    margin: auto;
    .react-reveal {
      animation: none !important;
      opacity: 1 !important;
    }
  }
  img {
    width: 200px;
    margin: auto;
  }
`

const Text = styled.p`
  padding: 5px 0 20px;
  max-width: 660px;
  margin: auto;
  color: white;
  text-align: center;
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 8px 0 64px;
  }
`

const Certifications = ({ title, text, items }) => {
  const sliderRef = useRef()

  const settings = {
    dots: false,
    slidesToShow: items.length,
    slidesToScroll: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          autoplay: true,
          infinite: false,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          afterChange: slick => {
            if (slick === items.length - 1) {
              setTimeout(function() {
                sliderRef.current.slickGoTo(0)
              }, 2000)
            }
          },
        },
      },
    ],
  }

  return (
    <Section color="primary" border>
      <Container>
        <Title color="white" className="title1" position="center">
          {title}
        </Title>
        <Text>{text}</Text>
        <WrapperSlider>
          <Slider ref={sliderRef} {...settings}>
            {items.map((item, index) => (
              <Flip key={index} left delay={index * 300}>
                <img
                  src={
                    item.logo_certification.localFile.childImageSharp.fluid.src
                  }
                  alt={
                    item.logo_certification.alt_text &&
                    item.logo_certification.alt_text.length > 0
                      ? item.logo_certification.alt_text
                      : "certification"
                  }
                />
              </Flip>
            ))}
          </Slider>
        </WrapperSlider>
      </Container>
    </Section>
  )
}

export default Certifications
