import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Img from "gatsby-image"

import Section from "../../elements/Section"
import Title from "../../elements/Title"
import globalVariables from "../../globalVariables"
import GuillemetIcon from "../../../images/icons/guillemet.svg"
import WrapperDots, { Dots } from "./DotsCarousel"
import BannerImage from "../../../images/temoignages/temoignages-clients.jpg"

const Background = styled.div`
  background: url(${BannerImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
  padding: 40px 15px 60px;
  width: 100%;
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 80px 0 60px;
  }
`

const WrapperSlider = styled.div`
  position: relative;

  background: white;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.14);

  .slick-prev,
  .slick-next {
    display: none !important;
  }
  max-width: 320px;

  @media (min-width: ${globalVariables.minTablet}) {
    max-width: 700px;
    margin: 50px auto;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: -27px;
      height: 75px;
      width: 75px;
      background: url(${GuillemetIcon});
      background-repeat: no-repeat;
      background-size: cover;
      transform: scaleX(-1);
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -43px;
      height: 75px;
      width: 75px;
      background: url(${GuillemetIcon});
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 1;
    }
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    max-width: 755px;
  }
`

const Card = styled.div`
  padding: 50px 15px;
  text-align: center;

  .gatsby-image-wrapper {
    height: 100%;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 50px 40px;
  }
`

const WrapperLogo = styled.div`
  height: 75px;
  max-width: 290px;
  margin: ${props =>
    props.noDots ? "15px auto 15px auto" : "60px auto 15px auto"};
  img {
    object-fit: contain !important;
    /* height: 75px !important;
    margin: auto; */
  }
`

const Nom = styled.p`
  margin-top: 16px;
  font-size: 20px;
  font-weight: 500;
  font-style: italic;
  color: ${props => props.theme.secondary};
  span {
    font-weight: normal;
  }
`

const CardText = styled.div`
  font-size: 20px;
  font-style: italic;
`

const Temoignage = ({
  title,
  items,
  imageHeight,
  imageMobileHeight,
  noDots,
}) => {
  const settings = {
    infinite: true,
    speed: 2000,
    // autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    dots: true,
    customPaging: function(i) {
      return <Dots />
    },
    appendDots: dots => <WrapperDots>{dots}</WrapperDots>,
  }

  return (
    <Section
      padding="0"
      imageHeight={imageHeight ? imageHeight : "650px"}
      imageMobileHeight={imageMobileHeight ? imageMobileHeight : "680px"}
      border
    >
      <Background>
        <WrapperContainer>
          {title && (
            <Title className="title1" position="center">
              {title}
            </Title>
          )}
          <WrapperSlider>
            <Slider {...settings}>
              {items.map((item, index) => (
                <Card key={index}>
                  <WrapperLogo noDots={noDots}>
                    <Img
                      fluid={
                        item.logo_temoignage.localFile.childImageSharp.fluid
                      }
                    />
                  </WrapperLogo>
                  <Nom>
                    {item.nom_temoignage}{" "}
                    <span>- {item.fonction_temoignage}</span>
                  </Nom>
                  <CardText>{item.texte_temoignage}</CardText>
                </Card>
              ))}
            </Slider>
          </WrapperSlider>
        </WrapperContainer>
      </Background>
    </Section>
  )
}

export default Temoignage
