import React, { useState } from "react"
import styled from "styled-components"
import Parser from "html-react-parser"

import Button from "../../elements/Button"
import { TitleH2 } from "../../elements/Title"

const Wrapper = styled.div`
  min-height: 460px;
  padding: 65px 0 80px 0;
  background: ${props => props.theme.secondary};
`
const Content = styled.div`
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 20px;
`

const List = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  padding-left: 0;
`

const Item = styled.li`
  list-style: none;
  padding-right: 15px;
  cursor: pointer;
  &.isactive button {
    background-color: white;
    span {
      color: ${props => props.theme.secondary};
    }
  }
`

const Text = styled.div`
  transition: all 0.2s ease-in-out;
  max-height: 210px;
  p {
    color: white;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
`

const Intro = styled.p`
  color: white;
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
`

const ValeursDesktop = ({ title, items }) => {
  const [valeur, setValeur] = useState({
    index: 0,
  })

  const handleClick = value => {
    setValeur({
      index: value,
    })
  }

  return (
    <Wrapper>
      <Content>
        <TitleH2 className="title1" color="white" position="center">
          {title}
        </TitleH2>
        <List>
          {items.map((item, index) => (
            <Item
              isActive={valeur.index === index}
              key={index}
              className={valeur.index === index ? "isactive" : null}
            >
              <Button
                title={item.titre_valeur}
                colors="borderWhiteHoverWhite"
                onClick={event => handleClick(index)}
              />
            </Item>
          ))}
        </List>

        <Text>
          <Intro>{items[valeur.index].intro_valeur}</Intro>
          {Parser(items[valeur.index].texte_valeur)}
        </Text>
      </Content>
    </Wrapper>
  )
}

export default ValeursDesktop
