import React from "react"
import styled from "styled-components"

import globalVariables from "../../globalVariables"
import theme from "../../theme"

const Content = styled.div`
  width: 572px;
  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const Title = styled.h1`
  margin: 0px 0px 10px 0px;
  span {
    display: block;
    font-size: 60px;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: 0;
  }
  .white {
    color: white;
  }
  .green {
    color: ${theme.green};
  }
  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    span {
      font-size: 36px;
    }
  }
`
const Subtitle = styled.div`
  color: ${theme.lightBlue};
  font-size: 33px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0;
  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    font-size: 28px;
  }
`

const MainContent = styled.div`
  color: ${theme.white};
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0;
  margin-top: 30px;
  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    font-size: 18px;
  }
`

const Tag = styled.span`
  color: white;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0;
  margin: 0px 0px 10px 0px;
  text-transform: uppercase;
  .green {
    color: ${theme.green};
  }
`

const TitleOneLine = styled.h1`
  margin: 0px 0px 10px 0px;
  span {
    display: block;
    font-size: 60px;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: 0;
  }
  .white {
    color: white;
  }
  .green {
    color: ${theme.green};
  }
  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    span {
      font-size: 36px;
    }
  }
`

const LeftCard = ({
  content: { titleFirstLine, titleSecondLine, subTitle, mainContent },
  hasTag,
  isContactPage,
  isMentionsLegalesPage,
}) => {
  const TitleComponent = () => {
    if (hasTag) {
      return (
        <>
          <Tag>{titleFirstLine}</Tag>
          <TitleOneLine>
            <span className="green">{titleSecondLine}</span>{" "}
          </TitleOneLine>
        </>
      )
    }
    if (isMentionsLegalesPage) {
      return (
        <>
          <TitleOneLine>
            <span className="white">{titleSecondLine}</span>{" "}
          </TitleOneLine>
        </>
      )
    }
    return (
      <Title>
        <span className="white">{titleFirstLine}</span>{" "}
        <span className="green">{titleSecondLine}</span>
      </Title>
    )
  }

  return (
    <>
      <Content>
        <TitleComponent />
        <Subtitle>{subTitle}</Subtitle>
        {isMentionsLegalesPage ? null : isContactPage ? (
          mainContent.map((item, key) => (
            <MainContent key={key}>{item}</MainContent>
          ))
        ) : (
          <MainContent>{mainContent}</MainContent>
        )}
      </Content>
    </>
  )
}

export default LeftCard
