import React from "react"
import styled, { css } from "styled-components"
import Img from "gatsby-image"

import globalVariables from "../../globalVariables"
import Section from "../../elements/Section"
import IntroCard from "../../elements/IntroCard/"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${globalVariables.minDesktop}) {
    flex-direction: row;
  }
`

const WrapperImages = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`

export const getBackgroundColor = props => {
  let background

  switch (props.background) {
    case "Gris":
      background = "rgba(81, 85, 93, 0.75)"
      break
    case "Blanc":
      background = "rgba(217, 226, 235, 0.75)"
      break
    case "Bleu":
      background = "rgba(82, 145, 206, 0.8)"
      break
    default:
      background = ""
  }

  return css`
    background: ${background};
  `
}

const Image = styled.div`
  flex: 0 1 50%;
  position: relative;
  ${getBackgroundColor};
  height: 140px;

  img {
    z-index: -1;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  & > .gatsby-image-wrapper img {
    transition: transform 0.2s ease-in-out !important;
  }
  &:hover > .gatsby-image-wrapper img {
    transform: scale(1.1) !important;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    height: 250px;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    height: 265px;
    flex: 0 1 33.33%;
  }
  @media (min-width: ${globalVariables.bigDesktop}) {
    height: 300px;
  }
`

const WrapperLogo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  img {
    z-index: 1;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    width: ${props => (props.width ? props.width : "150px")};
  }
`

const Clients = ({ title, text, items, englishPage }) => {
  return (
    <Section padding="0" border>
      <Container>
        <IntroCard
          englishPage={englishPage}
          title={title}
          text={text}
          buttonText="Nos références"
          buttonUrl="/nos-references"
          noH2
        />
        <WrapperImages>
          {items.map((item, index) => (
            <Image
              background={item.couleur_reference}
              key={index}
              // href={item.lien_reference}
            >
              <Img
                fluid={item.image_reference.localFile.childImageSharp.fluid}
              />
              <WrapperLogo width="165px">
                <Img
                  fluid={item.logo_reference.localFile.childImageSharp.fluid}
                />
              </WrapperLogo>
            </Image>
          ))}
        </WrapperImages>
      </Container>
    </Section>
  )
}

export default Clients
