import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import PropTypes from "prop-types"

import globalVariables from "../../globalVariables"
import openInNewTab from "../../../utils/openInNewTab"

import Buttonv2 from "../Buttonv2"

const Anchors = styled.div`
  display: flex;
  gap: 40px;
  justify-content: ${({ length }) => (length < 4 ? "center" : "initial")};
  margin: 0 auto;
  max-width: 1200px;
  padding-left: ${({ length }) => (length < 4 ? "0" : "50")}px;
  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    align-items: center;
    flex-direction: column;
    gap: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const Buttons = ({
  anchors,
  hasApplyButton,
  hasContactButton,
  hasTypeform,
}) => {
  const offsetAnchor = parseFloat(globalVariables.navbarHeight) + 20
  return (
    <>
      <Anchors length={anchors.length}>
        {anchors.map((anchor, index) => (
          <Buttonv2
            key={index}
            content={anchor.content}
            style="primaryDarkPurpleBackgroundLightBlueFont"
            type="rounded-header"
            anchor={anchor.anchor}
            offset={offsetAnchor}
          />
        ))}
        {hasContactButton && (
          <Buttonv2
            content="Vous avez un projet ?"
            style="GreenBackgroundDarkPurpleFont"
            type="rounded-header"
            handleClick={() => navigate("/contact")}
          />
        )}
        {hasTypeform && (
          <Buttonv2
            content="Vous avez un projet ?"
            style="GreenBackgroundDarkPurpleFont"
            type="rounded-header"
            handleClick={() =>
              openInNewTab("https://pbn7wnhu5yd.typeform.com/to/nE6oL5Fr")
            }
          />
        )}
        {hasApplyButton && (
          <Buttonv2
            content="Postuler"
            style="GreenBackgroundDarkPurpleFont"
            type="rounded-header"
            anchor="postuler"
            offset={offsetAnchor}
          />
        )}
      </Anchors>
    </>
  )
}

Buttons.propTypes = {
  hasContactButton: PropTypes.bool,
  hasTypeform: PropTypes.bool,
  offset: PropTypes.number,
  anchors: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      anchor: PropTypes.string,
    })
  ),
}

export default Buttons
