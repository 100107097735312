import React from "react"
import styled from "styled-components"

import globalVariables from "../../globalVariables"

import ImageMetier from "../../../images/banner-search-marketing.png"

const Image = styled.img`
  width: 300px;
  position: relative;
  z-index: 2;

  @media (max-width: ${globalVariables.maxMobile}) {
    display: none;
  }
`

const RightCardMetierNoArticles = () => (
  <div>
    <Image src={ImageMetier} />
  </div>
)

export default RightCardMetierNoArticles
