import React from "react"
import styled from "styled-components"

export const Dots = styled.a`
  display: block;
  width: ${props => (props.width ? props.width : "15")}px;
  height: ${props => (props.width ? props.width : "15")}px;
  background-color: ${props =>
    props.bgcDotInactive
      ? props.theme[props.bgcDotInactive]
      : props.theme["greyDarker"]};
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
`

const List = styled.ul`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  top: ${props => props.top}px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;

  li {
    list-style: none;
    margin: 7.5px;
    z-index: 1;
  }
  .slick-active a {
    background-color: ${props => props.theme[props.bgcDotActive]};
  }
`

const WrapperDots = ({ children, top = 50, bgcDotActive = "secondary" }) => {
  return (
    <div>
      <List top={top} bgcDotActive={bgcDotActive}>
        {" "}
        {children}{" "}
      </List>
    </div>
  )
}
export default WrapperDots
