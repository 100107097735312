import React from "react"
import styled, { keyframes } from "styled-components"
import { rubberBand } from "react-animations"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Fade from "react-reveal/Fade"

import Section from "../../elements/Section"
import Container from "../../elements/Container"
import Title, { TitleH2 } from "../../elements/Title"
import Button from "../../elements/Button"
import globalVariables from "../../globalVariables"
import WrapperDots, { Dots } from "./DotsCarousel"
import { NextArrow, PrevArrow } from "../../elements/ArrowCarousel"

const rubberBandAnimation = keyframes`${rubberBand}`

const Text = styled.p`
  padding: 5px 0 20px;
  max-width: 660px;
  margin: auto;
  text-align: center;
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 0 0 40px;
  }
`

const WrapperButton = styled.div`
  text-align: center;
  margin-bottom: 0;
  margin-top: 35px;
  @media (min-width: ${globalVariables.minTablet}) {
    margin-top: 55px;
    margin-bottom: 25px;
  }
`

const WrapperSlider = styled.div`
  position: relative;
  @media (min-width: ${globalVariables.minTablet}) {
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 5px;
      width: 80%;
      background: white;
      z-index: 0;
    }
  }
`

const Card = styled.div`
  padding: 55px 20px;
  background: white;
  margin: 12.5px;
  z-index: 1;
  &:hover img {
    animation: 1s ${rubberBandAnimation};
  }
  @media (min-width: ${globalVariables.minTablet}) {
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1);
  }
`

const CardText = styled.div`
  padding: 40px 0 0px;
  text-align: center;
  min-height: 100px;
`

const CardIcon = styled.img`
  width: 70px;
  margin: 25px auto 0;
`

const Accompagnement = ({ title, text, items, englishPage }) => {
  const settings = {
    infinite: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 0,
    initialSlide: 0,
    nextArrow: <NextArrow withBg />,
    prevArrow: <PrevArrow withBg />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          customPaging: function(i) {
            return <Dots />
          },
          appendDots: dots => <WrapperDots>{dots}</WrapperDots>,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: true,
          customPaging: function(i) {
            return <Dots />
          },
          appendDots: dots => <WrapperDots>{dots}</WrapperDots>,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Section color="greyLighter" overflow="hidden" id="accompagnements">
      <Container className="position-relative">
        <TitleH2 className="title1" position="center">
          {title}
        </TitleH2>
        <Text>{text}</Text>
        <WrapperSlider>
          <Slider {...settings}>
            {items.map((item, index) => (
              <Fade right key={index} delay={index * 300}>
                <Card>
                  <Title className="title2" position="center">
                    {item.titre_accompagnement}
                  </Title>
                  <CardIcon
                    src={
                      item.icone_accompagnement.localFile.childImageSharp.fluid
                        .src
                    }
                    alt="audit"
                  />
                  <CardText>{item.texte_accompagnement}</CardText>
                </Card>
              </Fade>
            ))}
          </Slider>
        </WrapperSlider>
        {!englishPage && (
          <WrapperButton>
            <Button
              title="Contactez-nous"
              colors="primaryWithBg"
              to="/contact"
              as="link"
              id="gtm-button-contactez-nous"
            />
          </WrapperButton>
        )}
      </Container>
    </Section>
  )
}

export default Accompagnement
