import React from "react"
import styled from "styled-components"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import theme from "../../theme"
import globalVariables from "../../globalVariables"

import LeftCard from "./LeftCard"
import RightCard from "./RightCard"
import Buttons from "./Buttons"
import RightCardArticle from "./RightCardArticle"
import RightCardRecrutement from "./RightCardRecrutement"
import RightCardMetierNoArticles from "./RightCardMetierNoArticles"

const Section = styled.div`
  background-color: ${theme.secondaryDarkPurple};
  padding-bottom: 40px;
  padding-top: ${({ hasBreadCrumb }) => (hasBreadCrumb ? "71" : "200")}px;
  @media (max-width: ${globalVariables.maxMobile}) {
    padding-top: 160px;
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding-bottom: 100px;
  padding-left: 50px;
  @media (max-width: ${globalVariables.maxMobile}) {
    padding-bottom: 50px;
    padding-left: 0;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const BannerV2 = ({
  anchors,
  articles,
  bannerLeftCardContent,
  hasApplyButton,
  hasBreadCrumb,
  hasContactButton,
  hasTypeform,
  hasSlider,
  hasTag,
  isAgencePage,
  isBlogPage,
  isContactPage,
  isEbooksPage,
  isHomePage,
  isFicheDePostePage,
  isMentionsLegalesPage,
  isMetierPage,
  isPrestationPage,
  isRecrutementPage,
  isReferencesPage,
}) => (
  <Section hasSlider={hasSlider} hasBreadCrumb={hasBreadCrumb}>
    <Wrapper>
      <ContentWrapper>
        <LeftCard
          content={bannerLeftCardContent}
          hasTag={hasTag}
          isContactPage={isContactPage}
          isMentionsLegalesPage={isMentionsLegalesPage}
        />
        {isEbooksPage || isContactPage || isMentionsLegalesPage ? (
          <RightCardArticle />
        ) : null}
        {isAgencePage ||
        isHomePage ||
        isReferencesPage ||
        isBlogPage ||
        isPrestationPage ||
        (isMetierPage && articles && articles.length > 0) ? (
          <RightCard hasSlider={hasSlider} articles={articles} />
        ) : null}
        {isMetierPage && articles && articles.length === 0 && (
          <RightCardMetierNoArticles />
        )}
        {isRecrutementPage || isFicheDePostePage ? (
          <RightCardRecrutement />
        ) : null}
      </ContentWrapper>
    </Wrapper>
    {anchors && (
      <Buttons
        anchors={anchors}
        hasApplyButton={hasApplyButton}
        hasContactButton={hasContactButton}
        hasTypeform={hasTypeform}
      />
    )}
  </Section>
)

export default BannerV2
