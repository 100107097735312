import React from "react"
import styled from "styled-components"
import Parser from "html-react-parser"

import Title, { TitleH2 } from "../../elements/Title"
import Button from "../../elements/Button"
import globalVariables from "../../globalVariables"

const Intro = styled.div`
  flex: 1 1 auto;
  background: ${props => (props.bgColor ? props.theme.primary : null)};
  padding: 40px 20px 30px;
  text-align: center;
  @media (min-width: ${globalVariables.minTablet}) {
    flex: 1 0 300px;
    padding: 60px 50px 50px 50px;
    text-align: left;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    flex: 1 0 515px;
    padding: 70px 75px 50px 75px;
  }
`

const Text = styled.div`
  margin: 57px 0 30px;
  p {
    color: ${props => (props.bgColor ? "white" : null)};
  }
  @media (min-width: ${globalVariables.minTablet}) {
    margin: 50px 0 35px;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    margin: 50px 0 35px;
  }
`

const IntroCard = ({
  bgColor,
  title,
  text,
  buttonText,
  buttonUrl,
  englishPage,
  noH2,
}) => {
  return (
    <Intro bgColor={bgColor}>
      {noH2 ? (
        <Title
          color={bgColor ? "white" : null}
          className="title1 trait"
          traitWidth="300px"
          traitLeft="-75px"
        >
          {title}
        </Title>
      ) : (
        <TitleH2
          color={bgColor ? "white" : null}
          className="title1 trait"
          traitWidth="300px"
          traitLeft="-75px"
        >
          {title}
        </TitleH2>
      )}
      <Text bgColor={bgColor}>{Parser(text)}</Text>
      {!englishPage && (
        <Button
          title={buttonText}
          colors={bgColor ? "primary" : "primaryWithBg"}
          to={buttonUrl}
          as="link"
          id="gtm-button-demander-un-audit"
        />
      )}
    </Intro>
  )
}

export default IntroCard
