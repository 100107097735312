import React from "react"
import styled from "styled-components"

export const Dots = styled.a`
  display: block;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
`

const List = styled.ul`
  margin: 25px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  li {
    list-style: none;
    margin-bottom: 0;
    z-index: 1;
  }
  .slick-active a {
    background-color: ${props => props.theme.primary};
  }
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 1px;
    width: 90%;
    background: white;
    z-index: 0;
  }
`

const WrapperDots = ({ children }) => {
  return (
    <div>
      <List> {children} </List>
    </div>
  )
}
export default WrapperDots
