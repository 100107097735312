import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { navigate } from "gatsby"

import globalVariables from "../../globalVariables"
import theme from "../../theme"

import WrapperDots, { Dots } from "../../Home/Temoignage/DotsCarousel"
import bannerPurple from "../../../images/banner-home-purple.png"
import bannerRocket from "../../../images/image-banner-home-v2.png"
import bannerPurpleAgence from "../../../images/banner_home_agence.png"
import arrowWhite from "../../../images/icons/icon_arrow_white_actualite.svg"

const CardHome = styled.div`
  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    display: none;
  }
`

const CardAgence = styled.div`
  height: 359px;
  left: -155px;
  position: absolute;
  top: -50px;
  width: 359px;
  z-index: 1;

  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    display: none;
  }
`

const WrapperSlider = styled.div`
  position: relative;
  .slick-list {
    width: 349px;
  }
  .slick-slider {
    z-index: 1;
  }
`

const WrapperContent = styled.div`
  cursor: pointer;
  padding-left: 35px;
  padding-top: 25px;
  position: relative;
`

const Actualite = styled.div`
  background-color: ${theme.purple};
  border: none;
  border-radius: 20px;
  box-shadow: 8px 10px 0px ${theme.primaryDarkPurple};
  height: 302px;
  margin-right: 30px;
  margin-top: 30px;
  width: 349px;
  z-index: 2;
  position: relative;
  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    display: none;
  }
`

const ActualiteTitle = styled.p`
  background-color: ${theme.primaryDarkPurple};
  color: ${theme.white};
  font-size: 20px;
  font-weight: 700;
  height: 27px;
  line-height: 1.3;
  letter-spacing: 0;
  margin-bottom: 20px;
  text-align: center;
  width: fit-content;
  padding: 0 10px;
`

const ActualiteTag = styled.p`
  align-items: center;
  border: 2px solid ${theme.green};
  border-radius: 100px;
  color: ${theme.green};
  display: flex;
  font-size: 15px;
  font-weight: 700;
  height: 27px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 1.3;
  text-align: center;
  width: fit-content;
  padding: 0 20px;
`

const ActualiteContent = styled.p`
  border: 0;
  color: #ffffff;
  font-size: 18px;
  font-weight: normal;
  height: 135px;
  line-height: 1.5;
  letter-spacing: 0;
  width: 260px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`

const ImageHome = styled.img`
  height: 404px;
  margin-right: 50px;
  margin-top: -30px;
  width: 404px;
`

const ImageAgence = styled.img``

const ImgArrow = styled.img`
  bottom: 0;
  height: 50px;
  position: absolute;
  right: 10px;
  width: 50px;
`

const RightCard = ({ articles, hasSlider, isEbooksPage }) => {
  const settings = {
    arrows: false,
    infinite: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    dots: true,
    customPaging: function(i) {
      return <Dots bgcDotInactive="primaryDarkPurple" width="10" height="10" />
    },
    appendDots: dots => (
      <WrapperDots
        top="310"
        bgcDotActive="white"
        bgcDotInactive="primaryDarkPurple"
      >
        {dots}
      </WrapperDots>
    ),
  }
  return (
    <>
      {isEbooksPage ? <ImageHome src={bannerPurple} /> : null}
      {!hasSlider ? (
        <CardHome>
          <ImageHome src={bannerRocket} />
        </CardHome>
      ) : (
        <WrapperSlider>
          <CardAgence>
            <ImageAgence src={bannerPurpleAgence} />
          </CardAgence>
          <Actualite>
            <Slider {...settings}>
              {articles &&
                articles.map((item, index) => (
                  <WrapperContent
                    key={index}
                    onClick={() => navigate(`/blog${item.node.path}`)}
                  >
                    <ActualiteTitle>
                      {item.node.acf.type_article}
                    </ActualiteTitle>
                    <ActualiteTag>{item.node.acf.theme_article}</ActualiteTag>
                    <ActualiteContent>
                      {item.node.acf.titre_article}
                    </ActualiteContent>
                    <ImgArrow src={arrowWhite} />
                  </WrapperContent>
                ))}
            </Slider>
          </Actualite>
        </WrapperSlider>
      )}
    </>
  )
}

export default RightCard
